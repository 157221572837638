export default [
  {
    path: '/',
    name: 'splash',
    component: () => import('/@front/views/Splash.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/facilitator',
    name: 'facilitator',
    component: () => import('../views/Facilitator.vue'),
    children: [
      {
        path: 'periods',
        name: 'facilitator-periods',
        component: () => import('../views/facilitator/periods/View.vue'),
      },
      {
        path: 'dashboard',
        name: 'facilitator-dashboard',
        component: () => import('../views/dashboard/Index.vue'),
      },
      {
        path: 'info',
        name: 'facilitator-info',
        component: () => import('../views/info/Index.vue'),
        children: [
          {
            path: 'role',
            name: 'facilitator-info-role',
            component: () => import('../views/info/Role.vue'),
          },
          {
            path: 'profit-loss',
            name: 'facilitator-info-profit-loss',
            component: () => import('../views/info/ProfitLoss.vue'),
          },
          {
            path: 'profit-loss-customer',
            name: 'facilitator-info-profit-loss-customer',
            component: () => import('../views/info/ProfitLoss.vue'),
          },
          {
            path: 'assortment',
            name: 'facilitator-info-assortment',
            component: () => import('../views/info/Assortment.vue'),
          },
          {
            path: 'pricelist',
            name: 'facilitator-info-pricelist',
            component: () => import('../views/info/Pricelist.vue'),
          },
          {
            path: 'demand-delivery',
            name: 'facilitator-info-demand-delivery',
            component: () => import('../views/info/DemandDelivery.vue'),
          },
          {
            path: 'abp',
            name: 'facilitator-info-abp',
            component: () => import('../views/info/Abp.vue'),
          },
          {
            path: 'sop',
            name: 'facilitator-info-sop',
            component: () => import('../views/info/Sop.vue'),
          },
          {
            path: 'promo-plan',
            name: 'facilitator-info-promo-plan',
            component: () => import('../views/info/PromoPlan.vue'),
          },
          {
            path: 'contracts',
            name: 'facilitator-info-contracts',
            component: () => import('../views/info/Contracts.vue'),
          },
          {
            path: 'sku-results',
            name: 'facilitator-info-sku-results',
            component: () => import('../views/info/SkuResults.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/participant',
    name: 'participant',
    component: () => import('../views/Participant.vue'),
    children: [
      {
        path: 'quarter',
        name: 'quarter',
        component: () => import('../views/participant/tasks/Index.vue'),
        children: [
          {
            path: 'tasks/:task',
            name: 'quarter-task',
            component: () => import('../views/participant/tasks/View.vue'),
          },
        ],
      },
      {
        path: 'year',
        name: 'year',
        component: () => import('../views/participant/tasks/Index.vue'),
        children: [
          {
            path: 'tasks/:task',
            name: 'year-task',
            component: () => import('../views/participant/tasks/View.vue'),
          },
        ],
      },
      {
        path: 'pc',
        name: 'pc',
        component: () => import('../views/participant/pc/Index.vue'),
      },
      {
        path: 'info',
        name: 'participant-info',
        component: () => import('../views/info/Index.vue'),
        children: [
          {
            path: 'role',
            name: 'participant-info-role',
            component: () => import('../views/info/Role.vue'),
          },
          {
            path: 'profit-loss',
            name: 'participant-info-profit-loss',
            component: () => import('../views/info/ProfitLoss.vue'),
          },
          {
            path: 'assortment',
            name: 'participant-info-assortment',
            component: () => import('../views/info/Assortment.vue'),
          },
          {
            path: 'pricelist',
            name: 'participant-info-pricelist',
            component: () => import('../views/info/Pricelist.vue'),
          },
          {
            path: 'demand-delivery',
            name: 'participant-info-demand-delivery',
            component: () => import('../views/info/DemandDelivery.vue'),
          },
          {
            path: 'abp',
            name: 'participant-info-abp',
            component: () => import('../views/info/Abp.vue'),
          },
          {
            path: 'sop',
            name: 'participant-info-sop',
            component: () => import('../views/info/Sop.vue'),
          },
          {
            path: 'promo-plan',
            name: 'participant-info-promo-plan',
            component: () => import('../views/info/PromoPlan.vue'),
          },
          {
            path: 'contracts',
            name: 'participant-info-contracts',
            component: () => import('../views/info/Contracts.vue'),
          },
          {
            path: 'sku-results',
            name: 'participant-info-sku-results',
            component: () => import('../views/info/SkuResults.vue'),
          },
        ],
      },
      {
        path: 'inbox',
        name: 'inbox',
        component: () => import('../views/participant/messages/Index.vue'),
        children: [
          {
            path: 'messages/:message',
            name: 'message',
            component: () => import('../views/participant/messages/View.vue'),
          },
        ],
      },
      {
        path: 'dashboard',
        name: 'dashboard',
        component: () => import('../views/dashboard/Index.vue'),
      },
    ],
  },
]

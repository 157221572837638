import '/@front:css/style.css'
import 'floating-vue/dist/style.css'

import { createApp } from 'vue'

import { registerComponents } from '/@front/shared/components'
import { registerPlugins } from '/@front/shared/plugins'

import App from './App.vue'
import { router } from './shared/router'

const app = createApp(App)

app.use(router)

registerComponents(app)
registerPlugins(app)

app.mount('#ccep-app')

const isRunningStandalone =
  (window.navigator as any).standalone === true || window.matchMedia('(display-mode: standalone)').matches
if (isRunningStandalone) {
  if (typeof screen?.orientation?.lock === 'function') {
    try {
      screen.orientation.lock('landscape-primary').catch(() => {
        /* nothing */
      })
    } catch (e) {
      /* nada */
    }
  }
}

<template>
  <router-view></router-view>

  <Teleport :to="flitsTarget">
    <div class="flits">
      <TransitionGroup name="flits">
        <div class="message" v-for="flits in messages" :class="flits.level" :key="flits.id">
          <p>{{ flits.message }}</p>
        </div>
      </TransitionGroup>
    </div>
  </Teleport>
</template>

<script lang="ts" setup>
import { computed, provide } from 'vue'

import { messages } from '/@front/shared/flits'
import { hasVisibleModals, provideModal } from '/@front/shared/plugins/modal'

provide('appName', import.meta.env.VITE_APP_TITLE)
provideModal()

const flitsTarget = computed(() => hasVisibleModals.value ? 'dialog' : 'body')
</script>

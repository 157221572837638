import { ref } from 'vue'

import { uuid } from '/@front/utils/string'

type FlitsMessage = string
type MessageLevel = 'error' | 'warning' | 'info' | 'success'

export interface FlitsItem {
  id: string
  level: MessageLevel
  message: FlitsMessage
}

const messages = ref<Record<string, FlitsItem>>({})

const flitsFn = () => {
  const add = (level: MessageLevel, message: FlitsMessage) => {
    const id = uuid()
    messages.value[id] = { id, level, message }

    setTimeout(() => {
      remove(id)
    }, 8_000)

    return id
  }

  const remove = (id: string) => {
    if (!messages.value[id]) {
      return
    }

    delete messages.value[id]
  }

  const error = (message: FlitsMessage) => add('error', message)
  const warning = (message: FlitsMessage) => add('warning', message)
  const info = (message: FlitsMessage) => add('info', message)
  const success = (message: FlitsMessage) => add('success', message)

  return {
    error,
    warning,
    info,
    success,
    remove,
    messages,
  }
}

const flits = flitsFn()

export { flits, messages }

import FloatingVue from 'floating-vue'
import { App } from 'vue'
import VueClickAway from 'vue3-click-away'

import { i18nVue, options } from '/@front/shared/plugins/i18n'

export const registerPlugins = (app: App) => {
  app.use(i18nVue, options)
  app.use(VueClickAway)

  app.use(FloatingVue, {
    themes: {
      'info-tooltip': {
        $extend: 'dropdown',
      },
    },
  })
}

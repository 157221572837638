import { computed, inject, InjectionKey, nextTick, provide, reactive, Ref, ref, watch } from 'vue'

interface ModalComponentInterface {
  show(event?: MouseEvent): void
  hide(): void
  isShown: Ref<boolean>
  el: Ref<HTMLDialogElement | null>
}

class Modal {
  private modals = reactive(new Map<string, ModalComponentInterface>())
  public isTransitioning = ref(false)

  public register(name: string, methods: ModalComponentInterface) {
    if (this.modals.has(name)) {
      throw new Error(`modal with name "${name}" already registered`)
    }

    this.modals.set(name, methods)
  }

  public unregister(name: string) {
    this.modals.delete(name)
  }

  /**
   * @deprecated The open method should not be used, use show instead.
   */
  public open(name: string, event?: MouseEvent) {
    this.show(name, event)
  }

  public show(name: string, event?: MouseEvent) {
    const { show } = this.findModal(name)
    show(event)
  }

  /**
   * @deprecated The closeModal method should not be used, use hide instead.
   */
  public closeModal(name: string) {
    this.hide(name)
  }

  /**
   * @deprecated The close method should not be used, use hide instead.
   */
  public close(name: string) {
    this.hide(name)
  }

  public hide(name: string) {
    const { hide } = this.findModal(name)
    hide()
  }

  private findModal(name: string) {
    const modal = this.modals.get(name)

    if (!modal) {
      throw new Error(`modal with name "${name}" not registered`)
    }

    return modal
  }

  public isTransitioningModal(isTransitioning: boolean) {
    this.isTransitioning.value = isTransitioning
  }

  public get registeredModals() {
    return this.modals.keys()
  }

  public get hasActiveModals() {
    return computed(() => [...this.modals.values()].some((modal) => modal.isShown))
  }
}

export function provideModal() {
  provide(ModalKey, modalInstance)
}

export function useModal() {
  const modal = inject(ModalKey)

  if (!modal) {
    throw new Error('Modal not provided')
  }

  return modal
}

export const ModalKey: InjectionKey<Modal> = Symbol('modal')
export const modalInstance = new Modal()
export const hasVisibleModals = ref<boolean>(modalInstance.hasActiveModals.value)

watch([modalInstance.hasActiveModals, modalInstance.isTransitioning], ([hasActiveModals, isTransitioning]) => {
  if (! isTransitioning) {
    nextTick(() => (hasVisibleModals.value = hasActiveModals))
  }
})
